<template>
  <v-card tile height="100%">
    <v-card-title> {{ $t('inventory.name') }}</v-card-title>
    <v-card-text>
      <v-row
        ><v-col cols="6"
          ><div class="text-h3 font-weight-bold">
            {{ resourceData.openLots }}
            <span class="text-caption">{{ $tc('lot.name', 2) }}</span>
          </div>
        </v-col>
        <v-col cols="6">
          <div class="text-h3 font-weight-bold">
            {{ resourceData.totalFinalPartsInTheInventory }}
            <span class="text-caption"
              >{{ $tc('product.part', 2) }} In Stock</span
            >
          </div> </v-col
        ><v-col cols="6" offset="3">
          <div class="chart">
            <DoughnutChart
              :chart-data="resourceData.totalWoodInTheInventory.map(w => w.m3)"
              :chart-labels="
                resourceData.totalWoodInTheInventory.map(w => w.woodtype)
              "
            /></div
        ></v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import DoughnutChart from './DoughnutChart.vue'
export default {
  components: {
    DoughnutChart
  },
  props: {
    resourceData: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  max-width: 100%;
}
</style>
