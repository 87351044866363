<template>
  <v-card class="my-2" elevation="1">
    <v-list-item two-line>
      <v-list-item-avatar>
        <v-progress-circular
          rotate="360"
          :value="order.attributes.progress"
          color="teal accent-4"
        >
          {{ order.attributes.progress }}
        </v-progress-circular></v-list-item-avatar
      >
      <v-list-item-content>
        <v-list-item-title class="text-h5">
          {{ order.attributes.title }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ order.relationships.workTasks.data.length }}
          {{
            $tc(
              'workOrder.subWorkOrder',
              order.relationships.workTasks.data.length > 1 ? 2 : 1
            )
          }}</v-list-item-subtitle
        >
      </v-list-item-content>

      <v-list-item-action>
        <v-btn
          v-if="order"
          text
          small
          color="secondary"
          class="text-capitalize"
          :to="{ name: 'task-group-show', params: { taskGroupId: order.id } }"
          >{{ $t('actions.view') }}</v-btn
        >
      </v-list-item-action>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    orderCategory() {
      const { subCategory } = this.order.attributes
      return subCategory === 'purchase'
        ? {
            title: 'Incoming',
            name: 'purchase-order-show',
            color: 'green accent-3'
          }
        : {
            title: 'Outgoing',
            name: 'sale-order-show',
            color: 'amber accent-3'
          }
    }
  }
}
</script>
