<script>
import { Doughnut } from 'vue-chartjs'
export default {
  extends: Doughnut,
  props: {
    chartData: {
      type: [Array, Object],
      default: () => []
    },
    chartLabels: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      options: {
        legend: {
          position: 'bottom',
          align: 'center',
          labels: { usePointStyle: true }
        },

        responsive: true,
        aspectRatio: 1
        // maintainAspectRatio: false
      },
      colors: ['#540d6e', '#ee4266', '#ffd23f', '#3bceac', '#0ead69']
    }
  },
  mounted() {
    this.renderChart(
      {
        labels: this.chartLabels,
        datasets: [
          {
            backgroundColor: this.colors.slice(0, this.chartLabels.length),
            data: this.chartData
          }
        ]
      },
      this.options
    )
  }
}
</script>
