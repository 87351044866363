<template>
  <v-card class="my-2" elevation="1">
    <v-list-item three-line>
      <v-list-item-avatar
        ><v-icon :color="orderCategory.color">
          {{
            order.attributes.subCategory === 'purchase'
              ? 'mdi-import'
              : 'mdi-export'
          }}</v-icon
        ></v-list-item-avatar
      >
      <v-list-item-content>
        <div class="overline mb-4">{{ orderCategory.title }}</div>
        <v-list-item-title class="text-h5">
          {{ order.attributes.customAttributes.name }}
          <base-status-chip
            class="ml-4"
            small
            :status="order.attributes.status"
          ></base-status-chip>
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ order.attributes.updatedAt | moment('from') | sentenceCase }}
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action>
        <v-btn
          v-if="order"
          text
          small
          color="secondary"
          class="text-capitalize"
          :to="{ name: orderCategory.name, params: { orderId: order.id } }"
          >{{ $t('actions.view') }}</v-btn
        >
      </v-list-item-action>
    </v-list-item>
  </v-card>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  computed: {
    orderCategory() {
      const { subCategory } = this.order.attributes
      return subCategory === 'purchase'
        ? {
            title: this.$tc('purchase.name', 1),
            name: 'purchase-order-show',
            color: 'green accent-3'
          }
        : {
            title: this.$tc('sale.name', 1),
            name: 'sale-order-show',
            color: 'amber accent-3'
          }
    }
  }
}
</script>
