<template>
  <v-card tile outlined>
    <v-card-title>{{ $t('dashboard.shipmentUpdates') }}</v-card-title>
    <v-card-text>
      <v-row
        ><v-col cols="12"
          ><div class="text-h3 font-weight-bold">
            {{ orders.meta.total }}
            <span class="text-caption">{{
              $t('dashboard.totalShipments')
            }}</span>
          </div>
        </v-col>
      </v-row>
      <v-card-subtitle class="font-weight-bold">{{
        $t('dashboard.card.latestUpdatesText')
      }}</v-card-subtitle>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="12">
          <OrderListCard
            v-for="(order, i) in orders.data"
            :key="i"
            :order="order"
          /> </v-col
      ></v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { ORGANIZATION_ID } from '@/common/config'
import OrderListCard from './OrderListCard'
export default {
  components: {
    OrderListCard
  },
  data() {
    return {
      orders: { data: [], meta: {} }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions({
      filterOrders: 'order/filter'
    }),
    async fetchData() {
      const organizationId = ORGANIZATION_ID()
      const { data, meta } = await this.filterOrders({
        organizationId,
        subCategory: 'purchase'
      })
      this.orders.data = data.slice(0, 5)
      this.orders.meta = meta
    }
  }
}
</script>
