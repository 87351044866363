<template>
  <v-row>
    <v-col>
      <v-card outlined tile>
        <v-card-title>{{ $tc('purchase.name', 2) }}</v-card-title>
        <v-card-text>
          <v-row
            ><v-col cols="6"
              ><div class="text-h2 font-weight-bold">
                {{ resourceData.openIncomingShippings }}
                <span class="text-caption">{{ $t('orderStatus.open') }}</span>
              </div></v-col
            ><v-col cols="6" class="red lighten-5"
              ><div class="text-h2 red--text text--accent-2">
                {{ resourceData.dueToDateIncomingShippings }}
                <span class="text-caption">{{ $t('orderStatus.due') }}</span>
              </div></v-col
            >
          </v-row>
        </v-card-text>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-subtitle class="text--disabled">{{
              $t('dashboard.documentsPending')
            }}</v-list-item-subtitle>
            <v-list-item-title class="text-h4">
              {{
                resourceData.pendingDocumentsIncomingShipping
              }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-col>
    <v-col>
      <v-card outlined tile>
        <v-card-title>{{ $tc('sale.name', 2) }}</v-card-title>
        <v-card-text>
          <v-row
            ><v-col cols="6"
              ><div class="text-h2 font-weight-bold">
                {{ resourceData.openOutgoingShippings }}
                <span class="text-caption">{{ $t('orderStatus.open') }}</span>
              </div></v-col
            ><v-col cols="6" class="red lighten-5"
              ><div class="text-h2 red--text text--accent-2">
                {{ resourceData.dueToDateOutgoingShippings }}
                <span class="text-caption">{{ $t('orderStatus.due') }}</span>
              </div></v-col
            >
          </v-row>
        </v-card-text>
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-subtitle class="text--disabled">{{
              $t('dashboard.documentsPending')
            }}</v-list-item-subtitle>
            <v-list-item-title class="text-h4">
              {{
                resourceData.pendingDocumentsOutgoingShipping
              }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  components: {},
  props: {
    resourceData: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  max-width: 100%;
}
</style>
