<template>
  <div>
    <WorkOrderListCard
      v-for="(order, i) in workOrders"
      :key="i"
      :order="order"
    />
  </div>
</template>

<script>
import WorkOrderListCard from './WorkOrderListCard'
export default {
  components: {
    WorkOrderListCard
  },
  props: {
    workOrders: {
      type: [Array],
      required: true
    }
  }
}
</script>
