<template>
  <v-card tile outlined>
    <v-card-title>{{ $tc('workOrder.name', 2) }}</v-card-title>
    <v-card-text>
      <v-row
        ><v-col cols="12"
          ><div class="text-h3 font-weight-bold">
            {{ workOrders.meta.total }}
            <span class="text-caption"
              >Total {{ $tc('workOrder.name', 2) }}</span
            >
          </div>
        </v-col>
      </v-row>
      <v-card-subtitle class="font-weight-bold">{{
        $t('dashboard.card.latestUpdatesText')
      }}</v-card-subtitle>
      <v-divider></v-divider>

      <v-row>
        <v-col cols="12">
          <WorkOrderList :work-orders="workOrders.data" /> </v-col
      ></v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { ORGANIZATION_ID } from '@/common/config'
import WorkOrderList from './WorkOrderList'
export default {
  components: {
    WorkOrderList
  },
  data() {
    return {
      workOrders: { data: [], meta: {} }
    }
  },
  created() {
    this.setup()
  },
  methods: {
    ...mapActions({ filterTaskGroups: 'taskGroup/filter' }),
    async setup() {
      const organizationId = ORGANIZATION_ID()
      const { data, meta } = await this.filterTaskGroups({ organizationId })
      this.workOrders.data = data.slice(0, 5)
      this.workOrders.meta = meta
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  max-width: 100%;
}
</style>
