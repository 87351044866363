<script>
import { Bar } from 'vue-chartjs'
export default {
  extends: Bar,
  props: {
    chartData: {
      type: [Array, Object],
      default: () => []
    },
    chartLabels: {
      type: Array,
      required: true
    },
    chartBackgrondColors: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          position: 'bottom',
          align: 'center',
          labels: { usePointStyle: true }
        },
        scales: {
          xAxes: [
            {
              stacked: true
            }
          ],
          yAxes: [
            {
              stacked: true
            }
          ]
        }
      }
    }
  },
  computed: {
    dataIncomingShipment() {
      const {
        openIncomingShippings,
        dueToDateIncomingShippings
      } = this.chartData
      return [openIncomingShippings, dueToDateIncomingShippings]
    },
    dataOutgoingShipment() {
      const {
        openOutgoingShippings,
        dueToDateOutgoingShippings
      } = this.chartData
      return [openOutgoingShippings, dueToDateOutgoingShippings]
    }
  },
  mounted() {
    this.renderChart(
      {
        labels: this.chartLabels,
        datasets: [
          {
            label: this.$tc('purchase.name', 2),
            barPercentage: 0.5,
            // maxBarThickness: 100,
            barThickness: 'flex',
            backgroundColor: this.chartBackgrondColors[0],
            data: this.dataIncomingShipment
          },
          {
            label: this.$tc('sale.name', 2),
            barPercentage: 0.5,
            // maxBarThickness: 100,
            barThickness: 'flex',
            backgroundColor: this.chartBackgrondColors[1],
            data: this.dataOutgoingShipment
          }
        ]
      },
      this.options
    )
  }
}
</script>
