<template>
  <BaseOverlayLoader v-if="!dashboardData" :value="isLoading" />
  <v-row v-else class="grey lighten-5"
    ><v-col cols="12"> <OrdersMeta :resource-data="dashboardData"/></v-col>
    <v-col cols="12" md="6">
      <v-card flat height="100%">
        <v-card-title
          >{{ $tc('common.shipment', 2) }}
          {{ $t('common.status') }}</v-card-title
        >
        <v-card-text>
          <div class="chart">
            <StackedBarChart
              :chart-data="dashboardData"
              :chart-labels="[$t('orderStatus.open'), $t('orderStatus.due')]"
              :chart-backgrond-colors="['#82B1FF', '#FFD180']"
            />
          </div>
        </v-card-text> </v-card
    ></v-col>
    <v-col cols="12" md="6">
      <InventoryMeta v-if="dashboardData" :resource-data="dashboardData"
    /></v-col>
    <v-col cols="12" md="6"> <OrderList /></v-col>
    <v-col cols="12" md="6"> <WorkOrdersMeta /></v-col>
  </v-row>
</template>

<script>
import OrdersMeta from './components/OrdersMeta'
import OrderList from './components/OrderList'
import StackedBarChart from './components/StackedBarChart'
import InventoryMeta from './components/InventoryMeta'
import WorkOrdersMeta from './components/WorkOrdersMeta'
import BaseOverlayLoader from '@/components/BaseOverlayLoader'
import { mapActions, mapGetters } from 'vuex'
import { ORGANIZATION_ID } from '@/common/config'
export default {
  components: {
    OrdersMeta,
    OrderList,
    StackedBarChart,
    InventoryMeta,
    WorkOrdersMeta,
    BaseOverlayLoader
  },
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({ dashboardData: 'dashboard/dataAttributes' })
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions({ fetchDashboard: 'dashboard/fetch' }),
    async fetchData() {
      this.isLoading = true
      const organizationId = ORGANIZATION_ID()
      await this.fetchDashboard({ organizationId })
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.chart {
  max-width: 100%;
}
</style>
